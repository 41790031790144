.add-option {
  cursor: pointer;
  padding: 9px 0px;
} 
.question-from-wrapper .add-option.checkbox-input {
margin-left: 3rem;
}
.checkbox-review-option {
  width: 100% !important;
  display: flex;
  padding: 5px 0;
}

.checkbox-circle {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 3px;
  margin-right: 0.5rem;
}

.checkbox-review {
  width: 100%;
}
.checkbox-wrap{
  padding: 9px 0px;
}
.edit-icon svg{
  max-width: 15px;
  cursor: pointer;
  height: 15px;
}