#notFoundContainer{
    width: 100vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#notFoundContainer > *{
    margin-bottom: 20px;
}
#notFoundContainer > p {
    flex-grow: 0;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #646464;
}

#roundButton404 {
  width: 191px;
  height: 44px;
  flex-grow: 0;
  border-radius: 50px;
  border: solid 1px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
}
#roundButton404 > a{
    color: #000;
    text-decoration: none;
}
.style404ui{
    width: 300px;
}