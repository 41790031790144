span.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.MuiSlider-marked.css-1aznpnh-MuiSlider-root {
    width: 60%;
}

.survey-error{
        color: #e60000;
        margin: -18px 0 1.25em 0.2em;
        padding: 0;
        font-size: 13px;
        letter-spacing: 0.015em;
        font-family: "Roboto", sans-serif !important;
    
}