.template-background {
  background-image: url("../../../../../../public/group.svg");
  height: calc(100vh - 260px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5% 0 5%;
  position: relative;
  min-height: 470px;
}
.sure-btn {
  width: 100%;
  padding: 0.5em 0;
  color: #fff;
  background: #222;
  border: 1px solid #222;
  border-radius: 45px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 26px;
  font-size: 1.123em;
  line-height: 1.685em;
}
.explore-btn {
  width: 60%;
  padding: 0.6em 0;
  background: white;
  border: 1px solid black;
  border-radius: 45px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 26px;
  font-size: 1.123em;
  line-height: 1.685em;
}

.question-block {
  text-align: left;
  position: relative;
  width: 100%;
}

.up-down-arrow {
  position: fixed; 
  border-radius: 30px; 
  bottom: 40px;
  left: auto;
  right: 40px;
  z-index: 9999;
}

.up-down-arrow button {
  border: none;   
  background: #000;
  color: white; width: 48px; height: 38px;
}

.up-down-arrow button:first-child {transform: rotate(180deg); border-left: 1px solid #3a3a3a; border-top-right-radius: 30px; border-bottom-right-radius: 30px;}
.up-down-arrow button:last-child {transform: rotate(180deg); border-bottom-left-radius: 30px; border-top-left-radius: 30px;}


button.up-down:hover,button.up-down:focus{
  border: transparent !important;
}

.last-page-submit {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 10vh !important;
  position: absolute !important;
  bottom: -70px;
  left: 0px;
  width: calc(100% - 30px);
  max-width: 148px;
  background: none !important;
  border: 1px solid black !important;
  color: black;
}

.pollAdaniLogo {margin: 0 auto 15px;}
.pollAdaniLogo img {
  max-width: 134px;
}

/* arrow button style */
button.up-down {
  cursor: pointer;
}

button.up-down:hover svg g{
  stroke: #9d9d9d;
}
.question-block h2 {font-size: 22px; margin-bottom: 23px;}
.question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action.Joy-checked {background: #000; border: 1px solid #000; color: #fff;}
.question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-root.Joy-checked {color: #fff;}
.question-block .MuiFormGroup-root .MuiFormControlLabel-root {word-break: break-word;}
.question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action {border: 1px solid #ffc84e;}
.satisfy-dissatisfy-label {font-size: 14px; line-height: 20px; margin-top: 20px;}
.satisfy-dissatisfy-label p {margin-bottom: 0;}
.PollPreviewScreen .question-block .MuiInputBase-root {background: transparent !important; border: none !important; border-radius: 0 !important; border-bottom: 1px solid #ddd !important;}
.PollPreviewScreen .question-block .MuiInputBase-root textarea {border: none !important;}

.question-block .MuiFormGroup-root .MuiFormControlLabel-root .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {color: #333;}
.question-block .MuiFormGroup-root .MuiFormControlLabel-root .MuiButtonBase-root.MuiRadio-root.Mui-checked {color: #333;}

.surveySnackBar {bottom: 5rem !important;}
.surveySnackBar .MuiPaper-root {box-shadow: none; background: #222;}

/*************************Light Dark Theme CSS*************************************/
.DarkTheme .surveySnackBar .MuiPaper-root {background: #fff; color: #000;}
.DarkTheme.PollPreviewScreen .surveyServeyMsg {color: #fff !important;}
.DarkTheme.PollPreviewScreen .sure-btn {background: #fff; color: #000; border: 1px solid #fff !important;}
.DarkTheme.PollPreviewScreen .question-block {color: #fff !important;}
.DarkTheme.PollPreviewScreen .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {fill: #fff;}
.DarkTheme.PollPreviewScreen .Mui-checked .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="RadioButtonCheckedIcon"] {fill: #fff;}
.DarkTheme.PollPreviewScreen .JoyRadioGroup-root .JoySheet-root {border: 1px solid #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root {color: #fff !important;}
.DarkTheme.PollPreviewScreen .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="CheckBoxIcon"] {fill: #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root {color: #fff !important;}
.DarkTheme.PollPreviewScreen .JoyRadioGroup-root .JoySheet-root .JoyRadio-action.Joy-checked {background: #fff; border: 1px solid #fff;}
.DarkTheme.PollPreviewScreen .JoyRadio-root.Joy-checked label {color: #000;}
.DarkTheme.PollPreviewScreen .up-down-arrow button {background: #fff;}
.DarkTheme.PollPreviewScreen .up-down-arrow button:first-child {border-left: 1px solid #d1d1d1;}
.DarkTheme.PollPreviewScreen .last-page-submit {border: 1px solid#fff !important; color: #000 !important; background: #fff !important;}
.DarkTheme.PollPreviewScreen .question-block .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd span.MuiCheckbox-root.Mui-checked::after {
  top: 13px; left: 14px; width: 15px; height: 15px; content: ""; z-index: -1; position: absolute; background-color: #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root.MuiTextField-root textarea {color: #fff;}
.DarkTheme.PollPreviewScreen .MuiTextField-root .MuiFormLabel-root {color: #fff !important;}
.DarkTheme.PollPreviewScreen .question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action {border: 1px solid #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root:hover .MuiRadio-action {border: 1px solid #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root:hover label.MuiRadio-label {color: #000;} 
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root {background: transparent;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root label.MuiRadio-label {color: #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root.Joy-checked label.MuiRadio-label {color: #000;} 
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-radio.Joy-checked .MuiRadio-action {background: #fff; border: 1px solid #fff;}
.DarkTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-radio.Joy-checked + label.MuiRadio-label {color: #000;}
.DarkTheme.exploreScreen .surveyCard.thankyouCard .explore-btn {background: #fff; border: 1px solid #fff; color: #000 !important;}
.DarkTheme.exploreScreen .surveyCard.thankyouCard .ThankyouHeading {color: #fff !important;}
.DarkTheme.exploreScreen .surveyCard.thankyouCard .redeemPoints {color: #fff !important;}
.DarkTheme.exploreScreen .surveyCard.thankyouCard .smallText span {color: #000 !important;}
.DarkTheme.exploreScreen .surveyCard.thankyouCard .smallText {color: #fff !important;}

.LightTheme .surveySnackBar .MuiPaper-root {background: #000; color: #fff;}
.LightTheme.PollPreviewScreen .surveyServeyMsg {color: #000 !important;}
.LightTheme.PollPreviewScreen .sure-btn {background: #000; color: #000; border: 1px solid #000 !important;}
.LightTheme.PollPreviewScreen .question-block {color: #000 !important;}
.LightTheme.PollPreviewScreen .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {fill: #000;}
.LightTheme.PollPreviewScreen .Mui-checked .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="RadioButtonCheckedIcon"] {fill: #000;}
.LightTheme.PollPreviewScreen .JoyRadioGroup-root .JoySheet-root {border: 1px solid #000;}
.LightTheme.PollPreviewScreen .MuiFormControl-root {color: #000 !important;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root {background: transparent;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-label {color: #000;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-action.Joy-checked {background: #000; border: 1px solid #000;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-radio.Joy-checked + .MuiRadio-label {color: #fff;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-action {border: 1px solid #000;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root .MuiRadio-action:hover {background: #000; border: 1px solid #000;}
.LightTheme.PollPreviewScreen .MuiFormControl-root .MuiSheet-root:hover .MuiRadio-label {color: #fff;}
.LightTheme.PollPreviewScreen .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="CheckBoxIcon"] {fill: #000;}
.LightTheme.PollPreviewScreen .JoyRadioGroup-root .JoySheet-root .JoyRadio-action.Joy-checked {background: #000; border: 1px solid #000;}
.LightTheme.PollPreviewScreen .JoyRadio-root.Joy-checked label {color: #fff;}
.LightTheme.PollPreviewScreen .up-down-arrow button {background: #000;}
.LightTheme.PollPreviewScreen .up-down-arrow button:first-child {border-left: 1px solid #d1d1d1;}
.LightTheme.PollPreviewScreen .last-page-submit {border: 1px solid#000 !important; color: #fff !important; background: #000 !important;}
.LightTheme.PollPreviewScreen .question-block .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd span.MuiCheckbox-root.Mui-checked::after {
  top: 13px; left: 14px; width: 15px; height: 15px; content: ""; z-index: -1; position: absolute; background-color: #000;}
.LightTheme.PollPreviewScreen .MuiFormControl-root.MuiTextField-root textarea {color: #000;}
.LightTheme.PollPreviewScreen .MuiTextField-root .MuiFormLabel-root {color: #000 !important;}
.LightTheme.PollPreviewScreen .question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action {border: 1px solid #000;}
.LightTheme.PollPreviewScreen .MuiTextField-root .MuiInputBase-root {border-bottom: 1px solid #000 !important;}
.LightTheme.PollPreviewScreen .sure-btn {background-color: #000; color: #fff;}
.LightTheme.exploreScreen .surveyCard.thankyouCard .explore-btn {background: #000; border: 1px solid black; color: #fff !important;}
