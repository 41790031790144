text.highcharts-credits {
  display: none;
}

.chart-data-wrap {
  padding: 35px 0;
}
.notification_wrapper .chart-data-wrap:nth-child(2) {
  padding-top: 0;
}
.chart-data-wrap .question-no {
  color: #919191;
  font-size: 16px;
  margin-bottom: 10px;
}
.chart-data-wrap .chart-wrap {
  margin-left: 90px;
}
.chart-data-wrap .que-title {
  font-size: 22px;
  margin-bottom: 28px;
}

.chart-data-wrap .responce-title {
  font-size: 18px;
}

.chart-data-wrap tspan {
  stroke: none;
}

.channel-title {
  color: #666666;
  font-size: 12px;
}

.short-answer-type {
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-left: -90px !important;
}

.answer-value {
  padding: 1.5rem 2rem;
}

.answer-value:nth-child(n-1) {
  border-bottom: 1px solid #e2e2e2;
}

.left-icon {
  width: 1.5rem;
  /* margin-top: 0.45rem; */
  cursor: pointer;
}

.title-view-survey {
  width: 85%;
}

.border-unset {
  border: unset !important;
}

.color-white {
  color: white !important;
}

.best-result-img img {
  width: 18px;
  position: absolute;
  left: 0; top: 25%;
}
.best-result-img {
  font-size: 12px;
  position: relative;
  padding-left: 25px;
  padding-top: 7px; line-height: 1.5;
  cursor: default;
}

.viewSurvey .px-3 {padding-right: calc(var(--bs-gutter-x) * 0.5) !important; padding-left: calc(var(--bs-gutter-x) * 0.5) !important;}
.table-sm>:not(caption)>*>* {padding: 0.25rem 0 !important;}