.template-background {
  background-image: url("../../../../../../public/group.svg");
  height: calc(100vh - 260px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5% 0 5%;
  position: relative;
  min-height: 470px;
}
.sure-btn {
  width: 100%;
  padding: 0.5em 0;
  color: #fff;
  background: #222;
  border: 1px solid #222;
  border-radius: 45px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 26px;
  font-size: 1.123em;
  line-height: 1.685em;
}
.explore-btn {
  width: 60%;
  padding: 0.6em 0;
  background: white;
  border: 1px solid black;
  border-radius: 45px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 26px;
  font-size: 1.123em;
  line-height: 1.685em;
}

.question-block {
  text-align: left;
  position: relative;
  width: 100%;
}

.up-down-arrow {
  position: absolute;
  right: 41px;
  border-radius: 30px;
}

.up-down-arrow button {
  border: none;   
  background: #000;
  color: white; width: 48px; height: 38px;
}

.up-down-arrow button:first-child {transform: rotate(180deg); border-left: 1px solid #3a3a3a; border-top-right-radius: 30px; border-bottom-right-radius: 30px;}
.up-down-arrow button:last-child {transform: rotate(180deg); border-bottom-left-radius: 30px; border-top-left-radius: 30px;}


.last-page-submit {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 10vh !important;
  position: absolute !important;
  bottom: -70px;
  left: 0px;
  width: calc(100% - 30px);
  max-width: 148px;
  background: none !important;
  border: 1px solid black !important;
  color: black;
}

/* arrow button style */
button.up-down {
  cursor: pointer;
}
button.up-down:hover,button.up-down:focus{
  border: transparent !important;
}

button.up-down:hover svg g{
  stroke: #9d9d9d;
}
.question-block h2 {font-size: 22px; margin-bottom: 23px;}
.question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action.Joy-checked {background: #000; border: 1px solid #000; color: #fff;}
.question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-root.Joy-checked {color: #fff;}
.question-block .MuiFormGroup-root .MuiFormControlLabel-root {word-break: break-word;}
.question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action {border: 1px solid #000;}
.satisfy-dissatisfy-label {font-size: 14px; line-height: 20px; margin-top: 20px;}
.satisfy-dissatisfy-label p {margin-bottom: 0;}

.PollPreviewScreen .MuiTextField-root .MuiFormLabel-root {left: -11px; color: #333 !important; background: transparent; display: block; width: 430px; top: -20px;}
.PollPreviewScreen .MuiTextField-root .MuiFormLabel-root.MuiInputLabel-shrink {top: -28px;}
.PollPreviewScreen .MuiTextField-root .MuiInputBase-root {height: auto !important; border-bottom: 1px solid #ededed !important; padding: 0 !important; overflow: auto;}
.PollPreviewScreen .MuiTextField-root .MuiInputBase-root textarea {border: none !important; max-height: 200px; overflow: auto !important;}
.question-block .MuiFormControl-root {margin-top: 20px;}
.PollPreviewScreen .MuiInputBase-root {
  background: transparent !important; border: none !important; 
  border-bottom: 1px solid #ddd !important; 
  border-radius: 0 !important;
}
.PollPreviewScreen .question-block {
  min-height: 320px;
  padding: 0 0 50px;
}
.PollPreviewScreen .question-block .last-page-submit {
  bottom: 0;
  font-size: 14px!important;
  left: 0;
  max-width: 112px;
  padding: 7px 22px 6px;
}
.quickPoll-heading {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 25px;
  padding: 0; text-align: left;
}
.quickPoll-heading span {
  display: block;
  font-size: 44px;
}










/***************Poll Preview Page CSS************************/

.pollOuter {
  overflow: auto;
}
.viewPollDataProgressBar .pollOuter {
  overflow: hidden;
}

.pollSliderWrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  margin-left: 0;
  margin-bottom: 0;
}

.pollSliderWrapper.singleCard {
  margin: auto; text-align: center;
}

.gblWrapperView {
  max-width: 500px;
  display: block;
  margin: auto;
  padding: 8% 41px;
  height: 100%;             /* add for preview poll popup after continue */
  max-height: calc(100vh - 167px);
  overflow: auto;
}

.pollCard {
  padding: 20px;
  margin: 0 5px 0 20px;
  border-radius: 10px;
  width: 284px;
  display: inline-block;
  vertical-align: top;
  min-height: 410px;
  max-height: 410px;
  overflow: auto;
}

.pollCard:first-child {
  margin-left: 0;
}

.pollCard p {
  font-size: 1rem;
  line-height: 28px;
  margin-bottom: 1.25rem;
  font-weight: bold;
  word-break: break-all;
}

input[type="checkbox"]:checked {
  font-family: "SFProDisplayMedium", sans-serif;
}

.pollRow {
  padding: 14px 13px;
  border-radius: 10px;
  line-height: 1;
  background: #fff;
  margin-bottom: 1.5rem;
  position: relative;
  margin-right: 40px;
  height: 48px;
}

.PollPreviewScreen .gblWrapperView .pollSliderWrapper .pollRow {margin-right: 0;}


.pollRow:last-child {
  margin-bottom: 0;
}

.pollRow span {
  font-weight: bold; 
  font-size: 16px; 
  letter-spacing: 0.16px;
  color: #222;
  z-index: 99;
}
.pollRow span.optionResponseCount {
  position: absolute;
  right: -30px;
}

.pollRow span.progressBar {
  z-index: 9;
}

.progressBar {
  background-color: #f5c9c9;
  position: absolute;
  left: 0;
  height: 48px;
  top: 0;
  border-radius: 10px;
  z-index: 9;
  transition: 0.5s;
}

.thnksResponse {
  font-size: 16px;
  color: #222;
  letter-spacing: 0.19px;
  margin-top: 27px;
  font-weight: bold;
}

.pollCheckbox input[type="checkbox"]:checked + label {
  font-weight: bold;
}
.previewPollPopup .modal-header {padding: 0;}

.pollCheckbox {
  display: inline-flex;
  padding-top: 0;
  z-index: 99;
}

.pollCheckbox label {
  color: #000;
  font-family: "Roboto", sans-serif !important; line-height: 20px;
}

.pollCheckbox label a {
  color: #000;
  font-family: "Roboto", sans-serif !important;
}

.pollCheckbox .chkbox {
  cursor: pointer;
}

.pollCheckbox input {
  appearance: none;
  -webkit-appearance: none;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #000;
  border-radius: 1em;
  margin-right: 0.9375em;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  display: flex;
  outline: none !important;
  display: none;
}

.pollCheckbox input[type="checkbox"]:after {
  content: "\2713 ";
  color: #ffffff;
  display: none;
  text-align: center;
  line-height: 20px;
}

.pollCheckbox input[type="checkbox"]:checked {
  background-color: #222222;
  border: none;
  display: inline-block;
}

.pollCheckbox input[type="checkbox"]:checked:after {
  display: block;
}

.chk_box input {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #b2b2b2;
  margin-right: 0.9375em;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: #222;
  border-radius: 4px !important;
  font-size: initial;
}

.chk_box input[type="checkbox"]:after {
  content: "\2713 ";
  color: #fff;
  display: none;
}

.chk_box input[type="checkbox"]:checked {
  background-color: #222222;
  border: none;
  font-family: "Roboto", sans-serif;
}

.chk_box input[type="checkbox"]:checked:after {
  display: block;
  font-family: "SFProDisplayMedium", sans-serif;
}

.PollPreviewScreen .pollServeyMsg {
  background-size: cover;
  padding: 8% 35px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.PollPreviewScreen .previewLogoHeader {
  height: 45px;
}
.previewPollPopup .PollPreviewScreen .pollServeyMsg {
  padding: 5% 73px; text-align: center;
}
.previewPollPopup .PollPreviewScreen .gblWrapperView {
  padding: 5% 73px;
}
.previewPollPopup .PollPreviewScreen .sure-btn {
  font-size: 14px;
  height: 38px;
  margin: 10px auto;
  padding: 0;
  width: 128px;
}

.PollPreviewScreen {
  height: 100%;
}
.previewLogoHeader {display: flex; margin: 0 0 50px;}
.previewPollPopup .modal-dialog {
  max-width: 443px; height: calc(100vh - 40px); transform: translate(0, 20px) !important;
}
.previewPollPopup .modal-header {padding: 0; border: none;}
.surveyPreviewScreen .question-block .MuiInputBase-root {background: transparent !important; border: none !important; border-radius: 0 !important; border-bottom: 1px solid #ddd !important;}
.surveyPreviewScreen .question-block .MuiInputBase-root textarea {border: none !important;}

::-webkit-scrollbar {
width: 7px; height: 7px; opacity: 0;
visibility: hidden;
display: none;
}

::-webkit-scrollbar-track {
background: #f1f1f1; 
border-radius: 10px; opacity: 0;
}

::-webkit-scrollbar-thumb {
border-radius: 10px;
background: #888; opacity: 0;
}
