text.highcharts-credits {
  display: none;
}

.chart-data-wrap {
  padding: 35px 0;
}
.notification_wrapper .chart-data-wrap:nth-child(2) {
  padding-top: 0;
}
.chart-data-wrap .question-no {
  color: #919191;
  font-size: 16px;
  margin-bottom: 10px;
}
.chart-data-wrap .chart-wrap {
  margin-left: 90px;
}
.chart-data-wrap .que-title {
  font-size: 22px;
  margin-bottom: 28px;
}

.chart-data-wrap .responce-title {
  font-size: 18px;
}

.chart-data-wrap tspan {
  stroke: none;
}

.channel-title {
  color: #666666;
  font-size: 12px;
}

.short-answer-type {
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-left: -90px !important;
}

.answer-value {
  padding: 1.5rem 2rem;
}

.answer-value:nth-child(n-1) {
  border-bottom: 1px solid #e2e2e2;
}

.left-icon {
  width: 1.5rem;
  margin-top: 0.1rem;
  cursor: pointer;
}

.title-view-survey {
  width: 85%;
}

.viewSurvey .px-3 {padding-right: calc(var(--bs-gutter-x) * 0.5) !important; padding-left: calc(var(--bs-gutter-x) * 0.5) !important;}
.table-sm>:not(caption)>*>* {padding: 0.25rem 0;}


/***************************************/
.viewPollDataWrapper {max-width: 800px;}
.viewPollDataWrapper .pollName {font-weight: bold; font-size: 22px; color: #000;}
.viewPollDataWrapper .questionLabel {font-size: 16px; color: #8d8d8d; letter-spacing: 0.19px;} 
.viewPollDataWrapper .questionName {font-size: 20px; color: #333; font-weight: 500;}
.totalTarget , .totalResponse {font-size: 18px; color: #333;}
.totalTarget span , .totalResponse span {font-weight: bold;}
.viewPollDataWrapper .pollRow {background: #eaeaea;}
.viewPollDataWrapper .progressBar {background: #b1deff;}
.viewPollDataWrapper .viewPollDataProgressBar {max-width: 730px;}
.mb-6 {margin-bottom: 4rem;}
