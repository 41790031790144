.comment-input {
  border-radius: 8px !important;
  width: 100%;
  border: 1px solid lightgray;
  min-height: 10rem;
  padding: 1rem;
}

.comment-input:hover {
  border: 1px solid lightgray !important;
}

.comment-input:focus-visible {
  border: 1px solid lightgray !important;
}

.comment-input:focus {
  border: 1px solid lightgray !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
