.progress-bar-cotainer{
    width: 100%;
    height: 5px;
    background-color: #d8d8d8;
    position: absolute;
    top: 0; left: 0;
}
.progress-bar-cotainer .progress-bar {
    height: 5px;
    transition: width  ease-in-out 0.5s;
  }
  