.draft-survey-list {
  overflow: auto !important;
}
.draft-survey-model .modal-content {
  padding: 20px !important; height: 100%;
}
.draft-survey-model .modal-dialog{
  max-width: 430px;
  margin: 0 auto !important;
  height: calc(100vh - 40px);
  transform: translate(0, 20px) !important;
}
.draft-survey-model.heightAuto .modal-dialog {height: auto !important; max-width: 550px;}
.draft-survey-model.heightAuto .modal-dialog .modal-content {width: 550px !important;}
.draft-survey-model .modal-content .draft-survey-list {
  max-height: 392px;
}
.draft-survey-model .modal-content .text-end{
  padding: 0;
}
.slide-wrap{
  padding: 30px 0;
}
.nodata_head{
  font-size: large;
    font-weight: 600;
    text-align: center;
    margin: 5rem;
}