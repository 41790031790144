.short-answer {
  width: 60%;
  border-bottom: 1px solid #cacaca;
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.short-answer:hover {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.short-answer:focus {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.select-range {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.to-wrapper {
  width: 4% !important;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
}


.linear-scale-circle {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border: 1px solid gray;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 0.5rem;
  position: relative;
}

.rating-wrap {
  width: 1030px;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  justify-content: start;
}

.rating-wrap .rating-sub-text {
  font-size: 18px;
  margin: 0 10px;
  /* width: auto; */
  max-width: 120px;
  
}

.rating-wrap .rating-sub-number {
  width: auto;
  /* max-width: calc(100% - 280px); */
}
.rating-sub-number .row.col-1.mx-1 {
  display: inline-block;
  width: 64px;
}

.rating-wrap .rating-sub-number .linear-scale-circle{
  margin-right: 0;
}
.rating-number {
  margin-bottom: 10px;
}

.liner-number {
  min-width: 20px;
  display: inline-block;
  text-align: right;
}
.linear-select-wrap{
  min-width: 100px;
}