.question-from-wrapper {
  margin-left: 2rem !important;
}

.question {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.question:hover {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.question:focus {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.add-question {
  margin-left: 1rem !important;
  justify-content: right !important;
}

.delete-question {
  margin-left: 0 !important;
  justify-content: right !important;
}

.submit-btns {
  margin-top: 5rem !important;
}

.question-form .form-floating label {
  font-size: 22px;
  top: 0px !important;
  left: 35px;
  color: #666666a6;
}

.question-form
  .form-floating.question-type.custum-select
  label.MuiFormLabel-root {
  font-size: 16px;
  top: 3px !important;
}

.question-form .form-floating > .form-control:focus ~ label,
.question-form .form-control:not(:placeholder-shown) ~ label {
  display: none;
}

.question-form .form-floating.question-type.custum-select fieldset legend {display: block !important; overflow: visible; font-size: 16px; float: left;}
.question-form .form-floating.question-type.custum-select fieldset legend span {opacity: 1; margin-top: 17px; padding: 0;}
.question-form .form-floating.question-type.custum-select input.MuiSelect-nativeInput[value]:not([value=""]) + svg + fieldset legend {display: none !important; overflow: hidden;}
.question-form .form-floating.question-type.custum-select input.MuiSelect-nativeInput[value]:not([value=""]) + svg + fieldset legend span {opacity: 0;}


.custum-select .MuiInputLabel-shrink {
  display: none !important;
}

.custum-select legend {
  display: none !important;
}

.required {
  font-size: 18px;
  margin-bottom: 0;
  color: #222;
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 12px;
  margin-top: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -4px;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: lightgray;
}

input:focus + .slider {
  box-shadow: 0 0 1px lightgray;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
  background-color: #000;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.question-from-wrapper .add-option {
  margin-left: 2rem;
}

.mcq-input-wrap {
  padding: 9px 0px;
}

.question-form .form-control.question {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 20px !important;
}

.dropdown-icon {
  margin-right: 15px;
  min-width: 38px;
}

.question-index {
  padding: 0 !important;
  font-size: 22px;
  margin-top: 5px;
  font-weight: bold;
}

.question-input.pollQuestionInput {
  width: 100% !important;
}
.pollAddQuestion {
  max-width: calc(100% - 45px);
  margin: 0 0 0 26px;
  border-bottom: 1px solid #ddd;
}

.question-type {
  width: 30% !important;
}
.question-wrapper .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #cacaca !important; border-width: 1px !important;
}

.switch .slider.round {
  width: 40px;
}

.char-error {
  font-size: 14px;
  color: red;
  padding-top: 0.5rem;
  padding-left: 2rem !important;
}

@media (max-width: 1600px) {
  .add-question-btn {
    white-space: nowrap;
    width: 20% !important;
  }
}
