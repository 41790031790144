.draft-survey-list {
  overflow: auto !important;
}

.draft-survey-model .modal-content {
  padding: 25px;
}

.draft-survey-model .modal-dialog {
  max-width: 430px;
  margin: 0 auto !important;
}

.draft-survey-model .modal-content .draft-survey-list {
  max-height: 392px;
}

.draft-survey-model .modal-content .text-end {
  padding: 0;
}

.slide-wrap {
  padding: 30px 0;
}