.linearScaleBox {
  display: flex;
  align-items: center;
  width: 500px;
}
.linearScaleOption {
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}

.textOption {
  width: 100px;
  text-align: center;
}

.question-count {
  color: #919191;
  font-size: 16px;
}

.question-title {
  border-bottom: 1px solid #ededed;
  font-size: 22px;
  font-weight: bold;
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
