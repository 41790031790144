.short-answer {
  width: 60%;
  border-bottom: 1px solid #cacaca;
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
  background: unset !important;
}

.short-answer:hover {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.short-answer:focus {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
}

.short-review-option {
  width: 100% !important;
  display: flex;
  padding: 10px 0;
  
}

.short-ans-wrapper{
  border-bottom: 1px solid #ededed;
}
