.mWeb-survey-modal .modal-content {
  padding: 40px 40px 31px;
}

.mWeb-survey-modal {
  max-height: 700px;
  overflow: auto !important;

}

.draft-survey-model .modal-content .text-end {
  padding: 0;
}

.slide-wrap {
  padding: 30px 0;
}

.modalSubmit {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 10vh !important;
  width: calc(100% - 30px);
  max-width: 500px;
  margin: 0 auto !important;
  box-shadow: none !important;
}

/***** previw modal css *****/
.draft-survey-model.preview-survey #modal {max-height: 100%; overflow: initial !important; padding: 0;}
.draft-survey-model.preview-survey .mWeb-survey-modal h1 {font-size: 22px; margin-bottom: 1.5rem; font-weight: 600;}
.mWeb-survey-modal .template-background h1 {font-size: 30px !important; padding: 0; max-width: 350px; margin: 0 0 20px;}
.mWeb-survey-modal .template-background h1 + p {max-width: 90%; margin: 0 auto 10px;}
.mWeb-survey-modal .template-background .sure-btn {margin-bottom: 0;}
.mWeb-survey-modal .template-background .question-block textarea:hover ,
.mWeb-survey-modal .template-background .question-block textarea:focus {border: none !important;}
.mWeb-survey-modal .template-background .up-down-arrow {bottom: 0;}
.mWeb-survey-modal .template-background .last-page-submit {position: static !important; margin-top: 30px;}
.mWeb-survey-modal .template-background .question-block {max-height: 390px; margin-top: 0; padding: 2% 0 7%; word-break: break-word; overflow: auto;}
.mWeb-survey-modal .template-background .question-block h2 {margin-bottom: 20px;}
.mWeb-survey-modal .template-background .question-block h2 + div.MuiFormControl-root {width: 100%;}
.mWeb-survey-modal .template-background .question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action {border: 1px solid #000;}
.mWeb-survey-modal .template-background .question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-action.Joy-checked {background: #82b33c; border: 1px solid #82b33c;}
.mWeb-survey-modal .template-background .question-block .JoyRadioGroup-root .JoySheet-root .JoyRadio-root.Joy-checked {color: #fff;}

.mWeb-survey-modal .template-background .question-block::-webkit-scrollbar {width: 7px;}
.mWeb-survey-modal .template-background .question-block::-webkit-scrollbar-track {background: #f1f1f1; border-radius: 10px;}
.mWeb-survey-modal .template-background .question-block::-webkit-scrollbar-thumb {border-radius: 10px; background: #888;}

.anchorExploreSurvey{
  width: 100%;
  display: flex;
  text-decoration: none;
}