.draftmerchant_modal_pop .modal-content {
  width: 100% !important;
  /* height: 640px; */
  padding: 33px 25px 31px;
  border-radius: 12px;
  /* overflow: hidden; */
}

.draftmerchant_modal_pop .modal-title {
  margin-top: 25px;
  letter-spacing: 0.29px;
}

/* .draftmerchant_modal .modal-title:after {
  content: "";
  background: #f8f8f8;
  width: 100%;
  height: 1px;
  border: solid 1px #e2e2e2;
  position: absolute;
  top:140px;
  left: 0;
} */

.draft_head {
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: #333;
}

.draft_button {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #333;
  border-radius: 21px;
  border: solid 1px #999;
  background-color: #fff;
  padding: 10px 29px 9px 25px;
  cursor: pointer;
  margin: 0 5px;
}
.draftmerchant_modal_pop .modal-dialog {
  max-width: 700px !important;
}
.draftmerchant_modal_pop .modal-dialog .modal-body .row.p-4 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.draft_text {
  font-size: 14px;
  font-weight: normal;
  color: #666;
}

.draft-row-line_pop {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
}
.draftmerchant_modal_pop .modal-header {
  padding-bottom: 0;
}
.draftmerchant_modal_pop .modal-body {
  max-height: 500px;
  overflow-y: auto;
  margin-top: 0 !important;
  padding-top: 0;
}

#modal {
  max-height: 390px;
  margin-top: 10px;
  padding-bottom: 0;
}

#modal::-webkit-scrollbar {
  width: 7px;
}

#modal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

#modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

#smoothscroll {
  overflow-y: auto;
  margin: 10px 10px 0 0;
}

#smoothscroll::-webkit-scrollbar {
  width: 7px;
}

#smoothscroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

#smoothscroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

.mark-read-msg {
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  float: right;
  color: #333;
  padding: 7px 20px 0;
  margin: 0;
}
