

.mcq-review-option {
  width: 100% !important;
  display: flex;
  padding: 5px 0;
}

.mcq-review-option .mcq-circle{
  margin-top: 0;
}
.mcq-circle {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border: 1px solid gray;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 0.5rem;
  position: relative;
}

.mcq-circle.selected::after {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 4px;
  left: 4px;
  content: "";
  background: black;
  border-radius: 50%;
}

.mcq-input-text {
  border: none;
  cursor: pointer;
  background-color: #f4f4f4;
  margin-right: 0.5rem;
  /* border-bottom: 1px solid #cacaca ; */
}

.mcq-input-text:hover,
.mcq-input-text:focus {
  border: none !important;
  /* border-bottom: 1px solid #cacaca !important; */

}
.mcq-checkbox{
  width: 18px;
  height: 18px;
}

.mcq-review {
  width: 100%;
}

.custum-select .MuiSelect-select {
  display: flex;
  align-items: center;
}

.custum-select .MuiSelect-select .mcq-circle {
  margin-top: 0;
  margin-right: 15px;
}

.custum-select .root-MuiInputLabel-root{
  top: 0px !important;
  font-size: 20px;
}
.MuiButtonBase-root.Mui-selected {
  background-color: white !important;
}

.custum-select .MuiOutlinedInput-root {
  height: 60px;
  padding: 10px;
  width: 100%;
}
.custum-select .MuiOutlinedInput-root svg.MuiSvgIcon-root {right: 13px;}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #cacaca !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.Mui-selected {
  background-color: #222 !important; color: #fff !important;
}