.linearScaleBox {
  display: flex;
  align-items: center;
  width: 500px;
}
.linearScaleOption {
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}

.textOption {
  width: 100px;
  text-align: center;
}

.question-count {
  color: #919191;
  font-size: 16px;
}

.question-title {
  border-bottom: 1px solid #ededed;
  font-size: 22px;
  font-weight: bold;
  width: 100%;
  padding: 20px 0px ;
  margin-bottom: 30px;
}

/*****************Poll View Info*********************/

.pollInfoWrapper .PollLogo {border: 1px solid #dbdbdb; height: 330px; border-radius: 12px; padding: 20px; display: flex; justify-content: center; align-items: center;}
.pollInfoWrapper .infoColsData label {color: #666; font-size: 12px; display: block;}
.pollInfoWrapper .infoColsData span {color: #222; font-size: 16px; font-weight: 500; display: block;}
.pollInfoWrapper .infoColsData {margin-bottom: 1.5rem; padding-right: 5rem;}
.viewPollTabs .MuiButtonBase-root {width: 50%; max-width: 50%; font-size: 16px; padding: 18px;}
.viewPollTabs .MuiButtonBase-root:hover {border: none !important;}
.viewPollTabs .MuiButtonBase-root.Mui-selected {border: none !important; background: transparent !important;}
.analysisWrapper .col-sm-3.autocomplete_dropdown {width: 260px;}
.analysisWrapper .col-sm-3 .btn-filter {width: 156px; font-size: 16px !important; padding: 6px 12px !important; height: 52px;}
.analysisWrapper .analysisTopRow , .pollTopRow {margin: 2rem 0 2.5rem;} 
.tabPanelContent .MuiBox-root {padding: 0;}
.analysisPieChart .chart-box-view {display: flex;}
.analysisPieChart .chart-box-view .color-dotted {flex-basis: 50%; height: 220px; display: flex; justify-content: center; align-items: center;}
.analysisPieChart .chart-box-view .color-dotted ul {margin-top: 0 !important;}
.analysisPieChart .chart-box-view .color-dotted ul li {width: 100%;}
.analysisPieChart .chart-box-view .color-dotted ul li span.male-dot {background-color: #308be2; height: 10px; width: 10px; margin-right: 5px; border-radius: 50%; display: inline-block;}
.analysisPieChart .chart-box-view .color-dotted ul li span.female-dot {background-color: #da5dd3; height: 10px; width: 10px; margin-right: 5px; border-radius: 50%; display: inline-block;}
.analysisPieChart .chart-box-view .color-dotted ul li span.other-dot {background-color: #949090; height: 10px; width: 10px; margin-right: 5px; border-radius: 50%; display: inline-block;}
.analysisPieChart .chart-box-view .pie-chart-no-data {height: 220px; margin-top: 0; font-size: 16px; display: flex; justify-content: center; align-items: center; flex-basis: 50%;}