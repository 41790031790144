.survey-header-wrapper {
  margin-bottom: 1.5rem !important;
}

.viewPollDataProgressBar .split {
  display: inline-flex;
  padding-top: 0;
  z-index: 99;
}

.page-title {
  padding-top: 1rem;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: #333;
}

.btn-black {
  border-color: #222 !important;
  background: #222 !important;
  cursor: pointer !important;
  border-radius: 30px !important;
}

.margin-0 {
  margin: 0 !important;
}

.back-icon {
  cursor: pointer !important;
}

.question-form .form-floating .form-control:hover {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-color: #ced4da !important;
}

.question-form .form-floating>label {
  color: #333;
  font-size: 22px;
  font-weight: bold;
}

.question-form .form-floating .form-control:focus+label {
  font-weight: 400;
  font-size: 14px;
}
.question-form .form-floating .form-control:focus{
  border-color: #ced4da !important;

}
.question-form select:hover,
.question-form select:focus {
  border-color: #cacaca!important;
}
.question-form  .form-floating > .form-select{
padding: 15px 20px;
}
.question-form  .form-floating > .form-select option{
padding: 10px;
background: #fff;
}
.question-form  .form-floating > .form-select option:hover{
  background: white;
}
.survey-dilog-box{
  padding: 20px 18px;
  border-radius: 8px;
  background-color: #f6f6f6;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 20px  0 50px 0;
}
.create-survey .autocomplete_dropdown .MuiInputLabel-shrink{
  padding-top: 20px !important;
}

.create-survey .MuiPickersPopper-root{
  margin-top: 10px !important;
}
.btn.btn-primary.create-survey-btn {
  width: auto !important;
  }

  .MuiButtonBase-root.Mui-selected{
    color: #333 !important;
  }

.draft-survey-model .modal-header {padding: 0; border: none;}
.draft-survey-model .draft-modal-header h3 {margin-bottom: 5px; line-height: 1; font-size: 24px;}
.draft-survey-model .draft-survey-list.modal-body {padding-top: 0;}


.pollFileUpload {position: relative;}
.pollFileUpload.Faded #pollBackgroundImage .drop_zone_box {background: #e9e9e9; opacity: 0.5;}
.pollFileUpload.Faded #surveyBackgroundImage .drop_zone_box {background: #e9e9e9; opacity: 0.5;}
.pollFileUpload .close_cir {
    top: 15px;
    background-color: rgba(201, 205, 208, -0.43);
    right: 19px;
    cursor: pointer;
}

span.colorPalette {
  width: 70px;
  height: 40px;
  position: absolute;
  border-radius: 8px;
  right: 12px;
  top: 10px;
}